<template>
  <div>
    <vx-card class="sign-up-details mt-8">
      <div class="card-title">
        <h2>Sign Up</h2>
      </div>
      <!-- Tabing for Sign Up process -->
      <vs-tabs :value="activeTab">
        <!-- Tab 1 (Step 1 - Service Selection) -->
        <vs-tab label="Step 1 - Service Selection" @click="activeTab = 0">
          <div class="basepackage">
            <div class="vx-row">
              <div class="vx-col w-full mb-6 text-center package-btn-group">
                <!-- <vs-button color="primary" type="border" :class="plansType=='annual'?'isActive':''" @click="plansType='annual'"
                  >Annual <small>Save 25%</small></vs-button>
                <vs-button color="primary"  type="border" :class="plansType=='monthly'?'isActive':''" @click="plansType='monthly'"> Monthly </vs-button> -->
                <div class="package-btn package-btn-1" :class="plansType=='annual'?'active':''" @click="plansType='annual'">Annual <small>Save 25%</small></div>
                <div class="package-btn package-btn-2" :class="plansType=='monthly'?'active':''" @click="plansType='monthly'">Monthly</div>
              </div>
              <div class="vx-col w-full mb-6 text-center">
                <a href="https://www.visualvisitor.com/pricing" target="_blank" >See a full pricing plan summary here</a>
              </div>
            </div>
            <div class="flex w-full justify-center flex-wrap ">

                <vx-card class="package-card md:w-2/5  w-full mb-6 text-center bg-first-gradient" :class="planName == 'lite'?'active':''" @click="planName='lite',nextButtonDisable=false">

                 <div class="package-title"> <h2>Lite Plan</h2></div>

                <div class="price-package">  <h6><span>$</span> {{ plansType=='annual'?annualPlanPrice:monthlyPlanPrice }}<span>/per month</span></h6></div>
                  <p>3 User accounts</p>
                  <!-- <h4>Buyer Intent Data</h4>
                  <p>Weekly updates</p>
                  <p>1 campaign</p>
                  <p>6 Keywords</p>
                  <p>up to 400 Footprints a month</p> -->
                  <h4>B2B Contact Data</h4>
                  <p>400 Monthly Credits</p>
                  <p>Unused credits rollover</p>
                  <h4>Website Visitor ID</h4>
                  <p>5,000 Monthly Visitors</p>

                  <p>Real-time Notifications</p>

                  <h4>Email Tracking</h4>
                  <p>Gmail and Outlook plugin</p>

                  <p>300 Monthly Emails</p>
                                    <vs-button  color="primary" @click="planName = 'lite'"
                    >Select This Plan</vs-button
                  >
                </vx-card>


                <vx-card class="package-card premium-plan md:w-2/5 w-full mb-6 text-center bg-second-gradient" :class="planName == 'premium'?'active':''" @click="planName = 'premium'">

                  <div class="package-title"><h2>Premium Plan</h2></div>
                 <div class="price-package">  <h6><span>$</span> - Custom-</h6></div>

                  <p>up to Unlimited User accounts</p>

                  <h4>Buyer Intent Data</h4>
                  <p>Daily updates</p>
                  <p>up to unlimited campaign</p>
                  <p>50 Keywords per campaign</p>
                  <p>up to 3,000 Footprints per campaign</p>
                  <h4>B2B Contact Data</h4>
                  <p>up to unlimited Credits</p>
                  <p>Unused credits rollover</p>
                  <h4>Website Visitor ID</h4>
                  <p>up to unlimited Monthly Visitors</p>

                  <p>Real-time Notifications</p>

                  <h4>Email Tracking</h4>
                  <p>Gmail and Outlook plugin</p>

                  <p>up to unlimited Monthly Emails</p>
                  <vs-button color="success"  @click="planName = 'premium'"
                    >Select This Plan</vs-button
                  >
                  <!-- @click="getRequestPremiumPlan()" -->
                </vx-card>

            </div>
            <!-- <h3>Base Package Includes <span>$59.00/Month</span></h3>
                        <div class="visitor-list-area">
                            <div class="visitor-list">
                                <h5>Anonymous Visitor Identification</h5>
                                <ul>
                                    <li>Real-Time Visitor Identification</li>
                                    <li>Unlimited Real-Time Visitor Notifications</li>
                                    <li>Access to Control Panel</li>
                                    <li>Email Campaign Integration</li>
                                    <li>Form Capture</li>
                                    <li>Third Party Integrations</li>
                                    <li>3 User Accounts</li>
                                    <li>Reporting</li>
                                    <li>20,000 Page Views Per Month *</li>
                                </ul>
                            </div>
                            <div class="visitor-list">
                                <h5>Call Tracking</h5>
                                <ul>
                                    <li>5 Local Numbers</li>
                                    <li>100 Local Usage Minutes</li>
                                    <li>50 Text Messages</li>
                                    <li>Call Recording</li>
                                    <li>Text Message Inbox</li>
                                    <li>Call Scoring and Tagging</li>
                                    <li>Access to Control Panel</li>
                                    <li>Reporting</li>
                                </ul>
                            </div>
                            <div class="visitor-list">
                                <h5>Email Tracking for Sales</h5>
                                <ul>
                                    <li>Google Gmail and Microsoft Outlook Integration</li>
                                    <li>500 Email Tags per day per user</li>
                                    <li>Email Templates</li>
                                    <li>Template Sharing</li>
                                    <li>Access to Control Panel</li>
                                    <li>Reporting</li>
                                </ul>
                                <p>* Overage billed in 5,000 page view increments.</p>
                                <p>Each additional 5,000 page view are $5.</p>
                                <p>An average account receives approximately 2,500 page views per month.</p>
                            </div>
                        </div>
                    </div>
                    <div class="basepackage">
                        <h2>Optional Services</h2>
                        <h3>Additional User Account <span>$10.00/Month</span></h3>
                        <div class="visitor-list-area">
                            <div class="visitor-list">
                                <h5>Includes</h5>
                                <ul>
                                    <li>Unlimited Real-Time Visitor Notifications</li>
                                    <li>Access to Control Panel</li>
                                    <li>Reporting</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="user-input sm:pl-4 pl-0">
                        <label class="user-name-label">Purchase Additional Users</label>
                        <v-select
                            v-model="purchesAdditionalUser"
                            :options="purchesAdditionalUserOptions"
                            :clearable="false"
                        />
                        <span class="sm:pr-4 pr-0"><strong>${{purchesAdditionalUserPrice}}/Month</strong></span>
                    </div>
                    <div class="basepackage mt-6">
                        <h3 v-if="provisionedPhoneNumberCountLocal > 5">{{provisionedPhoneNumberCountLocal}} Additional Local Call Tracking Numbers <span>${{provisionedPhoneNumberCountLocalPrice}}/Month</span></h3>
                        <h3 v-if="provisionedPhoneNumberCountTollFree > 0">{{provisionedPhoneNumberCountTollFree}} Additional Local Call Tracking Numbers <span>${{provisionedPhoneNumberCountTollFreePrice}}/Month</span></h3> -->
          </div>
        </vs-tab>
        <!-- Tab 2 (Step 2 - Billing Information) -->
        <vs-tab label="Step 2 - Billing Information" @click="activeTab = 1">
          <form data-vv-scope="signUpForm" class="signup-form">
            <div class="vx-row">
              <div class="vx-col md:w-1/3 w-full mb-6">
                <vs-input
                  class="w-full"
                  label="Name on Card"
                  v-model="billingNameOnCard"
                  name="billingNameOnCard"
                  v-validate="'required'"
                />
                <span class="text-danger text-sm">{{
                  errors.first("signUpForm.billingNameOnCard")
                }}</span>
              </div>
              <div class="vx-col md:w-1/3 w-full mb-6">
                <span class="vs-input--label">Card Type</span>
                <v-select
                  v-model="billingCardType"
                  :options="billingCardTypeOptions"
                  :clearable="false"
                />
              </div>
              <div class="vx-col md:w-1/3 w-full mb-6">
                <vs-input
                  class="w-full"
                  label="Credit Card Number"
                  v-model="billingCardNumber"
                  name="billingCardNumber"
                  v-validate="'required|credit_card'"
                />
                <span
                  class="text-danger text-sm"
                  v-show="billingCardNumberError"
                  >{{ billingCardNumberError }}</span
                >
                <span class="text-danger text-sm">{{
                  errors.first("signUpForm.billingCardNumber")
                }}</span>
                <p>Please use numbers only, no spaces or dashes.</p>
              </div>
              <div class="vx-col lg:w-1/3 w-full mb-6">
                <span class="block"
                  ><label class="vs-input--label"
                    >Credit Card Expiration</label
                  ></span
                >
                <div class="card-date">
                  <v-select
                    class="w-full float-left"
                    v-model="billingCardExpMonth"
                    :options="billingCardExpMonthOptions"
                    :clearable="false"
                  />
                  <span class="text-danger" v-if="!checkBillingMonthYear()">
                    Expiry month is less than current month so please select appropriate month
                  </span>
                </div>
                <span class="date-arrow"> / </span>
                <div class="card-date">
                  <v-select
                    class="w-full float-left"
                    v-model="billingCardExpYear"
                    :options="billingCardExpYearOptions"
                    :clearable="false"
                  />
                </div>
              </div>
              <div class="vx-col md:w-1/3 w-full mb-6">
                <vs-input
                  label="Billing Address 1"
                  v-model="billingAddress1"
                  name="billingAddress1"
                  v-validate="'required'"
                />
                <span class="text-danger text-sm">{{
                  errors.first("signUpForm.billingAddress1")
                }}</span>
              </div>
              <div class="vx-col md:w-1/3 w-full mb-6">
                <vs-input label="Billing Address 2" v-model="billingAddress2" />
              </div>
              <div class="vx-col md:w-1/3 w-full mb-6">
                <vs-input
                  class="w-full"
                  label="City"
                  v-model="billingCity"
                  name="billingCity"
                  v-validate="'required'"
                />
                <span class="text-danger text-sm">{{
                  errors.first("signUpForm.billingCity")
                }}</span>
              </div>
              <div class="vx-col md:w-1/3 w-full mb-6">
                <vs-input
                  class="w-full"
                  label="State"
                  v-model="billingState"
                  name="billingState"
                  v-validate="'required'"
                />
                <span class="text-danger text-sm">{{
                  errors.first("signUpForm.billingState")
                }}</span>
              </div>
              <div class="vx-col md:w-1/3 w-full mb-6">
                <vs-input
                  class="w-full"
                  label="Zip"
                  v-model="billingZip"
                  name="billingZip"
                  v-validate="'required'"
                />
                <span class="text-danger text-sm">{{
                  errors.first("signUpForm.billingZip")
                }}</span>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  label="Billing Email"
                  v-model="billingEmail"
                  name="billingEmail"
                  v-validate="'required|email'"
                />
                <span class="text-danger text-sm">{{
                  errors.first("signUpForm.billingEmail")
                }}</span>
              </div>
            </div>
          </form>
        </vs-tab>
        <!-- Tab 3 (Step 3 - Confirm and Complete) -->
        <vs-tab label="Step 3 - Confirm and Complete" @click="activeTab = 2">
          <div class="vx-row pb-8">
            <div class="vx-col xl:w-2/3 col-cus-12 w-full">
              <vx-card>
                <div class="card-title">
                  <h2 class="w-auto py-2">Billing Summary</h2>
                </div>
                <vs-table class="table-list" :data="[1, 2]">
                  <template slot="thead">
                    <vs-th sort-key="service">Service</vs-th>
                    <vs-th sort-key="notes">Notes</vs-th>
                    <vs-th sort-key="qty">Qty</vs-th>
                    <vs-th sort-key="price">Price</vs-th>
                    <vs-th sort-key="subtotal">Sub Total</vs-th>
                  </template>
                  <template slot-scope="{ data }">
                    <tbody>
                      <vs-tr>
                        <vs-td>
                          <p>Lite Plan - Visual Visitor</p>
                        </vs-td>
                        <vs-td>
                          <p>
                            <!-- Includes 3 user accounts, price does not include
                            page view overage. -->
                            {{ plansType=='annual'?"Annual Billing":"Monthly Billing" }}
                          </p>
                        </vs-td>
                        <vs-td>
                          <p>1</p>
                        </vs-td>
                        <vs-td>
                          <p>${{ plansType=='annual'?annualPlanPrice*12:monthlyPlanPrice }}</p>
                        </vs-td>
                        <vs-td>
                          <p class="text-secondary">${{ plansType=='annual'?annualPlanPrice*12:monthlyPlanPrice }}</p>
                        </vs-td>
                      </vs-tr>
                      <!-- <vs-tr>
                                                <vs-td>
                                                    <p>Additional User Accounts</p>
                                                </vs-td>
                                                <vs-td>
                                                    <p>Above the 3 accounts included.</p>
                                                </vs-td>
                                                <vs-td>
                                                    <p>{{purchesAdditionalUser}}</p>
                                                </vs-td>
                                                <vs-td>
                                                    <p>$10.00</p>
                                                </vs-td>
                                                <vs-td>
                                                    <p class="text-secondary">${{purchesAdditionalUserPrice}}</p>
                                                </vs-td>
                                            </vs-tr> -->
                      <vs-tr v-if="provisionedPhoneNumberCountLocal > 5">
                        <vs-td>
                          <p>Local Call Tracking Numbers</p>
                        </vs-td>
                        <vs-td>
                          <p>
                            Above the 5 local numbers included, price does not
                            include usage.
                          </p>
                        </vs-td>
                        <vs-td>
                          <p>{{ provisionedPhoneNumberCountLocal }}</p>
                        </vs-td>
                        <vs-td>
                          <p>$2.00</p>
                        </vs-td>
                        <vs-td>
                          <p class="text-secondary">
                            ${{ provisionedPhoneNumberCountLocalPrice }}
                          </p>
                        </vs-td>
                      </vs-tr>
                      <vs-tr v-if="provisionedPhoneNumberCountTollFree > 0">
                        <vs-td>
                          <p>Toll Free Call Tracking Numbers</p>
                        </vs-td>
                        <vs-td>
                          <p>Price does not include usage.</p>
                        </vs-td>
                        <vs-td>
                          <p>{{ provisionedPhoneNumberCountTollFree }}</p>
                        </vs-td>
                        <vs-td>
                          <p>$2.00</p>
                        </vs-td>
                        <vs-td>
                          <p class="text-secondary">
                            ${{ provisionedPhoneNumberCountTollFreePrice }}
                          </p>
                        </vs-td>
                      </vs-tr>
                    </tbody>
                    <tfoot>
                      <vs-tr>
                        <vs-td></vs-td>
                        <vs-td></vs-td>
                        <vs-td></vs-td>
                        <vs-td>
                          <p class="total-font font-medium">Total</p>
                        </vs-td>
                        <vs-td>
                          <span class="total-font text-secondary"
                            >${{ totalBillingAmount }}</span
                          >
                        </vs-td>
                      </vs-tr>
                    </tfoot>
                  </template>
                </vs-table>
              </vx-card>
            </div>
            <div class="vx-col xl:w-1/3 col-cus-12 w-full mt-cus-8">
              <vx-card class="credit-card">
                <div class="card-title">
                  <h2 class="w-auto py-2">Credit Card Information</h2>
                </div>
                <div class="credit-card-design bg-primary-gradient">
                  <span class="pay-img">
                    <img
                      v-show="billingCardType == 'American Express'"
                      src="../assets/images/american-express.png"
                    />
                    <img
                      v-show="billingCardType == 'MasterCard'"
                      src="../assets/images/mastercard.png"
                    />
                    <img
                      v-show="billingCardType == 'Visa'"
                      src="../assets/images/visa.png"
                    />
                  </span>
                  <span class="card-no">{{ billingCardNumber }}</span>
                  <div class="name-date">
                    <h5 class="float-left">
                      <span>Card Name</span>{{ billingNameOnCard }}
                    </h5>
                    <h5 class="float-right">
                      <span>Card Expiration</span>{{ billingCardExpMonth }}/{{
                        billingCardExpYear
                      }}
                    </h5>
                  </div>
                </div>
              </vx-card>
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
      <!-- Cancel and navigation button -->
      <div class="flex justify-end w-full mt-6 flex-wrap">
        <vs-button
          color="grey"
          type="border"
          class="mr-0 sm:mr-4 mb-2 sm:mb-0 w-full sm:w-auto grey-btn"
          @click="cancelSignUp()"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
        <vs-button
          v-if="activeTab == 0"
          :disabled="nextButtonDisable"
          color="primary"
          type="filled"
          class="mr-0 sm:mr-4 mb-2 sm:mb-0 w-full sm:w-auto"
          @click="planName == 'lite'?activeTab = 1:getRequestPremiumPlan()"
          >Next</vs-button
        >
        <vs-button
          v-if="activeTab == 1"
          color="primary"
          type="filled"
          class="mr-0 sm:mr-4 mb-2 sm:mb-0 w-full sm:w-auto"
          @click="prevalidateBilling()"
          >Next</vs-button
        >
        <vs-button
          v-if="activeTab == 2"
          color="primary"
          type="filled"
          class="mr-0 sm:mr-4 mb-2 sm:mb-0 w-full sm:w-auto"
          @click="processSignup()"
          >Complete Purchase</vs-button
        >
      </div>
    </vx-card>
    <!-- Fail transaction message popup -->
    <vs-popup class="transaction-popup" :active.sync="errorPopup">
      <div class="transaction-details">
        <div class="popup-icon"><img src="../assets/images/error.svg" /></div>
        <h2>Transaction Failed</h2>
        <p>Please verify your credit card information and try again.</p>
        <vs-button
          @click="errorPopup = false"
          color="grey"
          type="border"
          class="grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>
    <!-- Transaction complete message popup -->
    <vs-popup class="transaction-popup" :active.sync="transactionSuccess">
      <div class="transaction-details">
        <div class="popup-icon"><img src="../assets/images/checked.svg" /></div>
        <h2>Transaction Complete</h2>
        <p>Thank you for your business!</p>
        <vs-button
          @click="redirectToDashboard()"
          color="grey"
          class="grey-btn"
          type="border"
        >{{ LabelConstant.buttonLabelClose }}</vs-button>
      </div>
    </vs-popup>
     <!-- Thank you message popup -->
    <vs-popup class="transaction-popup" :active.sync="thankyou">
      <div class="transaction-details text-left">
        <h2>Thank you</h2>
        <p>Thank you for your interest, a representative will contact you shortly to assist with understanding your requirements.</p>
        <vs-button
          @click="thankyou =false,nextButtonDisable=true"
          color="grey"
          class="grey-btn float-right"
          type="border"
          >{{ LabelConstant.buttonLabelClose }}</vs-button
        >
      </div>
    </vs-popup>
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import { Validator } from "vee-validate";
import store from "../store/store";
import { BButtonGroup, BButton } from "bootstrap-vue";

const dict = {
  custom: {
    billingNameOnCard: {
      required: "A Name is required",
    },
    billingCardNumber: {
      required: "A Credit Card Number is required",
      credit_card: "Please enter valid Credit Card Number.",
    },
    billingAddress1: {
      required:
        "Billing address must be more than 1 and less than 300 characters",
    },
    billingCity: {
      required: "A City is required",
    },
    billingState: {
      required: "A State is required",
    },
    billingZip: {
      required: "A Zip is required",
    },
    billingEmail: {
      required: "An Email is required",
      email: "Please enter a valid email address",
    },
  },
};
Validator.localize("en", dict);

export default {
  components: {
    VxCard,
    FormWizard,
    TabContent,
    vSelect,
    BButtonGroup,
    BButton,
  },
  data() {
    return {
      activeTab: 0,

      purchesAdditionalUser: "0",
      purchesAdditionalUserOptions: [],

      provisionedPhoneNumberCountLocal: 0,
      provisionedPhoneNumberCountTollFree: 0,

      billingNameOnCard: null,
      billingCardType: "Visa",
      billingCardNumber: null,
      billingCardExpMonth: "1",
      billingCardExpYear: new Date().getFullYear(),
      billingAddress1: null,
      billingAddress2: null,
      billingCity: null,
      billingState: null,
      billingZip: null,
      billingEmail: null,

      billingCardTypeOptions: ["Visa", "MasterCard", "American Express"],
      billingCardExpMonthOptions: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      billingCardExpYearOptions: [],
      billingCardNumberError: null,

      errorPopup: false,
      transactionSuccess: false,
      thankyou:false,
      nextButtonDisable:false,
      plansType:'monthly',
      planName:'lite',
      annualPlanPrice:149,
      monthlyPlanPrice:199
    };
  },
  mounted() {
    this.generateAdditionalUserOptions();
    this.getUserCounts();
    this.getProvisionedPhoneNumberCountForSignup();
    this.getYears();
  },
  computed: {
    /* For step 1 to manipulate number shows within Optional Services section
     * purchesAdditionalUserPrice()
     * provisionedPhoneNumberCountLocalPrice()
     * provisionedPhoneNumberCountTollFreePrice()
     */
    purchesAdditionalUserPrice() {
      let userPrice = parseFloat(this.purchesAdditionalUser * 10).toFixed(2);
      return userPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    provisionedPhoneNumberCountLocalPrice() {
      let localNumberPrice = parseFloat(
        this.provisionedPhoneNumberCountLocal * 2
      ).toFixed(2);
      return localNumberPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    provisionedPhoneNumberCountTollFreePrice() {
      let tollFreeNumberPrice = parseFloat(
        this.provisionedPhoneNumberCountTollFree * 5
      ).toFixed(2);
      return tollFreeNumberPrice
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    //For third step showing total billing amount
    totalBillingAmount() {
      let userPrice = this.purchesAdditionalUser * 10;
      let localNumberPrice = this.provisionedPhoneNumberCountLocal * 2;
      let tollFreeNumberPrice = this.provisionedPhoneNumberCountTollFree * 5;

      let total = parseFloat(
        (this.plansType=='annual'?(this.annualPlanPrice*12):this.monthlyPlanPrice) + userPrice + localNumberPrice + tollFreeNumberPrice
      ).toFixed(2);
      return (total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    },
  },
  methods: {
    //After successfull transaction it will redirect to Dashboard
    redirectToDashboard() {
      this.transactionSuccess = false;
      this.$router.push({
        name: "Dashboard",
        params: { reload: true },
      });
    },
    // It is usefull for Purchase Additional Users dropdown
    generateAdditionalUserOptions() {
      this.purchesAdditionalUserOptions = [];
      for (let i = 0; i < 40; i++) {
        this.purchesAdditionalUserOptions.push(i.toString());
      }
    },
    //Get user count
    getUserCounts() {
      this.$vs.loading();
      this.axios
        .get("/ws/ConfigUser/GetUserCounts")
        .then(() => {
          // let data = response.data;
          this.purchesAdditionalUser = "0";
          this.calculateUserPrice();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Additional Local Call Tracking Numbers
    getProvisionedPhoneNumberCountForSignup() {
      this.$vs.loading();
      this.axios
        .get("/ws/Signup/GetProvisionedPhoneNumberCountForSignup")
        .then((response) => {
          let data = response.data;
          this.provisionedPhoneNumberCountLocal = data[0].Value;
          this.provisionedPhoneNumberCountTollFree = data[1].Value;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Cancel Sign up process
    cancelSignUp() {
      this.activeTab = 0;
      this.provisionedPhoneNumberCountLocal = 0;
      this.provisionedPhoneNumberCountTollFree = 0;
      this.purchesAdditionalUser = "0";
      this.billingCardType = "Visa";
      this.billingCardExpMonth = "1";
      this.billingCardExpYear = new Date().getFullYear();
      this.billingCardNumber = null;
      this.billingNameOnCard = null;
      this.billingAddress1 = null;
      this.billingAddress2 = null;
      this.billingCity = null;
      this.billingState = null;
      this.billingZip = null;
      this.billingEmail = null;
      setTimeout(() => {
        this.errors.clear("signUpForm");
      }, 20);
      this.getUserCounts();
      this.getProvisionedPhoneNumberCountForSignup();
    },
    //Validate the billing details
    prevalidateBilling() {
      this.$validator.validateAll("signUpForm").then((result) => {
        if (result && this.checkBillingMonthYear()) {
          let prevalidateBillingParams = {
            Local_Number_Count: this.provisionedPhoneNumberCountLocal,
            Toll_Free_Number_Count: this.provisionedPhoneNumberCountTollFree,
            Number_Of_Users: this.purchesAdditionalUser,
            Billing_Card_Type: this.billingCardType,
            Billing_Card_Exp_Month: this.billingCardExpMonth,
            Billing_Card_Exp_Year: this.billingCardExpYear,
            Billing_Card_Number: this.billingCardNumber,
            Billing_Name_On_Card: this.billingNameOnCard,
            Billing_Address1: this.billingAddress1,
            Billing_Address2: this.billingAddress2,
            Billing_City: this.billingCity,
            Billing_State: this.billingState,
            Billing_Zip: this.billingZip,
            Billing_Email: this.billingEmail,
          };
          this.$vs.loading();
          this.axios
            .post("/ws/Signup/PrevalidateBilling", prevalidateBillingParams)
            .then(() => {
              this.activeTab = 2;
              this.billingCardNumberError = null;
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.showError(e);
              this.$vs.loading.close();
              if (e.response.data.Errors[0].Key == "Billing_Card_Number") {
                this.billingCardNumberError = e.response.data.Errors[0].Message;
              }
              this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000,
              });
            });
        }
      });
    },
    //Proceed for sign-up process
    processSignup() {
      let processSignupParams = {
        Local_Number_Count: this.provisionedPhoneNumberCountLocal,
        Toll_Free_Number_Count: this.provisionedPhoneNumberCountTollFree,
        Number_Of_Users: this.purchesAdditionalUser,
        Billing_Card_Type: this.billingCardType,
        Billing_Card_Exp_Month: this.billingCardExpMonth,
        Billing_Card_Exp_Year: this.billingCardExpYear,
        Billing_Card_Number: this.billingCardNumber,
        Billing_Name_On_Card: this.billingNameOnCard,
        Billing_Address1: this.billingAddress1,
        Billing_Address2: this.billingAddress2,
        Billing_City: this.billingCity,
        Billing_State: this.billingState,
        Billing_Zip: this.billingZip,
        Billing_Email: this.billingEmail,
      };
      this.$vs.loading();
      this.axios
        .post("/ws/Signup/ProcessSignup", processSignupParams)
        .then(() => {
          this.billingCardNumberError = null;

          let uid = this.$route.params.id;
          let VVtoken = localStorage.getItem("VVtoken");
          let token = JSON.parse(VVtoken);
          token[uid].isActiveFreeTrial = false;
          token[uid].isExpiredFreeTrial = false;
          localStorage.setItem("VVtoken", JSON.stringify(token));
          store.dispatch("updateAuthToken", token);
          // this.transactionSuccess=true
          this.$router.push({
            path: "/pages/thankyou/uid/" + uid,
            query: { amount: this.totalBillingAmount },
          });
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.showError(e);
          this.$vs.loading.close();
          this.activeTab = 1;
          this.errorPopup = true;
          if (e.response.data.Errors[0].Key == "Billing_Card_Number") {
            this.billingCardNumberError = e.response.data.Errors[0].Message;
          }
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    //Request Premium plan list
    getRequestPremiumPlan() {
      this.$vs.loading();
      this.axios
        .get("/ws/Signup/RequestPremiumPlan")
        .then((response) => {
          let data = response.data;
          if(data =='Complete'){
            this.thankyou = true
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getYears(){
      let cyArray = [];
            let startYear = new Date().getFullYear()
            let endYear = new Date().getFullYear() + 10
            for (let index = startYear; index < endYear; index++) {
                cyArray.push(index);
            }
            this.billingCardExpYearOptions = cyArray;
    },
    checkBillingMonthYear(){
      let month = new Date().getMonth();
      let cy = new Date().getFullYear();
      if(cy == this.billingCardExpYear){
        if(this.billingCardExpMonth < month+1){
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  },
};
</script>
